import { Injectable} from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';

import { URL } from '../../../app/core/configs/url';
import { LocalStorageService } from '../../../app/shared/services/local-storage.service';
import { KEY_STORAGE } from '../../../app/shared/enums/storage.enum';
import { BehaviorSubject, catchError, map, Observable, switchMap, tap, throwError, timeout } from 'rxjs';
import { User } from '../../core/models/user.interface';
import { UtilsService } from './utils.service';
import { FilesService } from './files.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private userAvatarBase64:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private branchUserSubject:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private affillitateUserSubject:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private adsByUserSubject:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private branch$:Observable<any> = this.branchUserSubject.asObservable();
  private affiliate$:Observable<any> = this.affillitateUserSubject.asObservable();
  private user$: Observable<User> = this.userSubject.asObservable();

  private timeoutRequisition:number = 10000;

  constructor(
    private httpClient:HttpClient,
    private localStorageService: LocalStorageService,
    private utilsService:UtilsService,
    private filesService:FilesService
  ){}

  getUser(){
    let login = this.localStorageService.getItem<string>(KEY_STORAGE.LOGIN);

    if (login === ''){
      login = 'undefined'
    }

    return this.httpClient.get<any>(`${URL.users}/${login}`)
  }
  
  //FIXME: implementar semaforo de user
  getUsersByUserCode():Observable<User | any>{
    let login = this.localStorageService.getItem<string>(KEY_STORAGE.LOGIN);
    var params:string = '';
    params += '?expand=ads';

    if (login === ''){
      login = 'undefined'
    }

    //login = 'willianalmeida'; //FIXME: retirar dado chumbado

    console.log("URL >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", `${URL.user.infos}/${login}${params}`)
    return this.httpClient.get<any>(`${URL.user.infos}/${login}${params}`, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
            //console.log("RESPOSTA::::::::::::::::::::::::::::::::::::::::::::::::::::::::", response)
          // Verifica o status da resposta e emite um erro se não for 200
          if (response.status !== 200) {
            throw { message: 'error in access user', errorDetails: response };
          }

          this.userSubject.next(response.body);
        }),
        map((response:any) => ( { message: 'success in access user', data: response.body } )),
        catchError(error => {
          return throwError(() => ({ message: 'error in access user', errorDetails: error }));
        })
      );
  }

  getAvatarUSer():Observable<any>{
    return this.userAvatarBase64.asObservable();
  }

  setAvatarUser(base64Complete:string){
    //console.log('DEU TUDO CERTO E O BASE64 ESTÁ SENDO SALVO: ', base64Complete)
    this.userAvatarBase64.next(base64Complete);
  }

  getAvatarByCode(code:string):Observable<any> {
    return this.httpClient.get<any>(`${URL.user.avatar}/${code}`, {
      observe:'response',
      responseType:'blob' as 'json'
    }).pipe(
      timeout(this.timeoutRequisition),
      tap((response:any) => {
        this.handleResponseHttp('Erro ao consultar avatar',response,undefined,false);
      }),
      switchMap((response:any) => {
        return this.filesService.returnBase64FileBlob(response.body).pipe(
          map((secondResponse:any) => {
            //console.log('LLLLLLLLLLLLLLLLLLLLLLLLLL: ', secondResponse);
            this.setAvatarUser(secondResponse);
            return { message:'Sucesso ao capturar base64', data:secondResponse }
          }),
          catchError((error:any) => {
            //console.log('222222222222222222222222: ',error);
            return throwError(() => (error))
          })
        )
      }),
      catchError((error:any) => {
        return throwError(() => (error))
      })
    );
  }

  getAdsByUser(codeUser:string):Observable<any> {
    var parameters = '?page=1&pageSize=2';
    return this.httpClient.get<any>(`${URL.settings.ads}/${codeUser}${parameters}`, {observe:'response'}).pipe(
      //timeout(this.timeoutRequisition),
      tap((response:any) => {
        this.handleResponseHttp('Erro ao ads por usuário',response,this.adsByUserSubject,true);
      }),
      map((response) => {
        return { message:'Sucesso ao consultar ads', data:response.body.items }
      }),
      catchError((error:any) => {
        return throwError(() => (error))
      })
    );
  }

  getBranchesByCode(codeBranch:string, updateObservableBranch:boolean = true):Observable<User | any>{
    return this.httpClient.get<any>(`${URL.user.branches}/${codeBranch}`, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          //console.log("RESPOSTA::::::::::::::::::::::::::::::::::::::::::::::::::::::::", response)

          if (response.status !== 200) {
            throw { message: 'erro ao consultar a filial '+codeBranch, errorDetails: response };
          }

          if(updateObservableBranch){
            this.branchUserSubject.next(response.body);
          }

        }),
        map((response:any) => ( { message: 'successo ao consultar a filial '+codeBranch, data: response.body } )),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao consultar a filial '+codeBranch, errorDetails: error }));
        })
      );
  }

  getAffiliatesByCode(codeaffiliate:string, updateObservableaffiliate:boolean = true):Observable<User | any>{
    return this.httpClient.get<any>(`${URL.user.affiliates}/${codeaffiliate}`, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          //console.log("RESPOSTA::::::::::::::::::::::::::::::::::::::::::::::::::::::::", response)

          if (response.status !== 200) {
            throw { message: 'erro ao consultar a coligada '+codeaffiliate, errorDetails: response };
          }

          if(updateObservableaffiliate){
            this.affillitateUserSubject.next(response.body);
          }
        }),
        map((response:any) => ( { message: 'successo ao consultar a coligada '+codeaffiliate, data: response.body } )),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao consultar a coligada '+codeaffiliate, errorDetails: error }));
        })
      );
  }

  cachedAdsByUser():Observable<any>{
    return this.adsByUserSubject.asObservable();
  }

  cachedUser(returnObservable:boolean = false):Observable<any>{
    return this.user$;
  }

  cachedAvatarUser():Observable<any>{
    return this.userAvatarBase64.asObservable();
  }

  cachedBranchUser():Observable<any> {
    return this.branch$;
  }

  cachedAffiliateUser():Observable<any>{
    return this.affiliate$;
  }

  getUserFull(): Observable<User>{
    return this.user$;
  }

  getTrajectories(page?:number, pageSize?:number, filter?:string, sort?:string):Observable<any>{
    const parameters = new HttpParams().appendAll({
        'page': page ? page : '',
        'pageSize': pageSize ? pageSize : '',
        'filter': filter ? filter : '',
        'sort':sort ? sort : ''
    });
    var returnHttp = this.httpClient.get<any>(`/api/v1/glb/trajectories`, { params: parameters });
    console.log("RETORNO DA REQUISICAO: ", returnHttp);
    return returnHttp;
  }

  private handleResponseHttp(msgErro:string, response:any, subject:BehaviorSubject<any> | undefined, updateValue:boolean){
    if(response.status !== 200){
      throw { message: msgErro, errorDetails: response };
    }
    if(updateValue){
      if(subject){
        response.body.items ? subject.next(response.body.items) : subject.next(response.body);
      }
    }
  }

  clearSubject(): void{
    this.userSubject.next(undefined);
    this.userSubject.next(undefined);
    this.userAvatarBase64.next(undefined);
    this.branchUserSubject.next(undefined);
    this.affillitateUserSubject.next(undefined);
    this.adsByUserSubject.next(undefined);
  }
}
