import { Injectable } from '@angular/core';
import { PoUploadFile } from '@po-ui/ng-components';
import { catchError, fromEvent, map, mergeMap, Observable, of, take, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor() { }


  returnBase64PoUploadFile(currentDoc:PoUploadFile):Observable<any> {
    const reader = new FileReader();
    reader.readAsDataURL(currentDoc.rawFile);

    return fromEvent(reader, 'load').pipe(
      take(1),
      map(() => {
        var base64String = ((reader.result as string).split('data:application/pdf;base64,'))[1];
        return { fileName:currentDoc.name, uidFile:currentDoc.uid, base64:base64String };
      }),
      catchError((error:any) => {
        console.log('Erro ao extrair base64 do arquivo '+currentDoc.name,'Erro: ',error);
        return of({messageError:'Erro ao extrair base64 do arquivo '+currentDoc.name});
      })
    );
  }


  returnBase64FileBlob(blob:Blob):Observable<any>{
    //console.log('-----------------------------------------------------------: ')
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return fromEvent(reader,'load').pipe(
      take(1),
      mergeMap(() => {
        return new Observable((observer) => {
          var stringBase64 = reader.result as string;
          const img = new Image();
          img.src = stringBase64;

          img.onload = () => {
            const width = img.width;
            const height = img.height;

            console.log('-----------------------------------------------------------: ')
            observer.next({ base64: stringBase64, width:width, height:height });
            observer.complete();
          };

          img.onerror = () => {
            observer.error('Erro na leitura do arquivo');
          };
        });
      }),
      catchError((error:any) => {
        return throwError(() => (error))
      })
    );
  }
}
