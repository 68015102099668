import { Injectable } from '@angular/core';
import { PoNotification, PoNotificationService, PoToasterOrientation } from '@po-ui/ng-components';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private poNotification:PoNotificationService) { }

  formatDate(date:string, ): string {
    var arrDate = date.split('-');
    var day = arrDate[2]; var month = arrDate[1]; var year = arrDate[0];
    var day = day.length == 1 ? '0'+day : day;
    var month = month.length == 1 ? '0'+month : month;
    return (day+'/'+month+'/'+year);
  }

  compareDates(date1:string, date2:string) {
    // 1 - date1 > date2    2 - date1 < date2  3 - date1 == date2
    var ret = 0;
    var arrDate1 = date1.split('-');
    var arrDate2 = date2.split('-');
    var year1 = arrDate1[0]; var month1 = arrDate1[1]; var day1 = arrDate1[2];
    var year2 = arrDate2[0]; var month2 = arrDate2[1]; var day2 = arrDate2[2];

    if ((String(month1)).length == 1) { month1 = "0" + month1 }
    if ((String(day1)).length == 1) { day1 = "0" + day1 }
    if ((String(month2)).length == 1) { month2 = "0" + month2 }
    if ((String(day2)).length == 1) { day2 = "0" + day2 }

    if (date1 == date2) {
      ret = 3;
    } else {
      if (year2 < year1) {
        ret = 1;
      } else if (year2 == year1) {
        if (month2 < month1) {
          ret = 1;
        } else if (month2 == month1) {
          if (day2 < day1) {
            ret = 1;
          }
        }
      } else if (year2 > year1) {
        ret = 2;
      }
      if (ret == 0) {
        ret = 2;
      }
    }
    return ret;
  }

  addZeroInDate(date:string){
    var arrDate:Array<any> = [];
    const separator = date.includes('-') ? '-' : '/';
    if(date.includes('-') || date.includes('/')){
      arrDate = date.split(separator);
      var day:string = arrDate[2]; var month:string = arrDate[1]; var year:string = arrDate[0];
      day = day.length > 1 ? day : '0'+day;
      month = month.length > 1 ? month : '0'+month ;
      return year+separator+month+separator+day;
    }else{
      return '';
    }
  }

  addZeroInLeftSide(data:Array<string>){
    data.forEach((element,index) => {
      data[index] = element.length < 2 ? '0'+element : element;
      console.log('element'+element);
    });
    console.log('data: ',data)
    return data;
  }

  formatD_M_Y(value:string){
    var aux = value.replaceAll(' ','');
    var newValue = '';
    for(var i=0; i<aux.length; i++){
      newValue += aux[i];
      if(aux[i] == 'a' || aux[i] == 'm'){
        newValue += ' ';
      }
    }
    return newValue;
  }

  returnDateMMyyyyFormat(date:string){
    var arrayD = date.split('-');
    var year:string = arrayD[0].length > 1 ? arrayD[0]+'' : '0'+arrayD[0];
    var month:string = arrayD[1].length > 1 ? arrayD[1]+'' : '0'+arrayD[1];
    const months:any = {
      '01':'Jan',
      '02':'Fev',
      '03':'Mar',
      '04':'Abr',
      '05':'Mai',
      '06':'Jun',
      '07':'Jul',
      '08':'Ago',
      '09':'Set',
      '10':'Out',
      '11':'Nov',
      '12':'Dez'
    }
    return months[month]+'/'+year
  }

  returnCurrentData() {
    var date = new Date();
    var day:any = date.getDate();
    var month:any = date.getMonth() + 1;
    var year = date.getFullYear();
    day = day.toString().length == 1 ? '0'+day : day;
    month = month.toString().length == 1 ? '0'+month : month;
    return (year + "-" + month + "-" + day);
  }

  logSuccess(message: string, style?:string) {
    var styleApplyed = style ? style : 'color: green; font-weight: bold;';
    console.log(`%cSuccess: ${message}`, styleApplyed);
  }

  compatibleTypes(data1:any,data2:any,expectedType:string=''):string{
    // 0 - totalmente incompativeis data1 e data2
    // 1 - compativeis entre si e compatíveis com o tipo esperado
    // 2 - compativeis apenas entre si
    var ret = '0';
    var type1 = typeof data1; var type2 = typeof data2;
    var isArrayType1 = Array.isArray(data1); var isArrayType2 = Array.isArray(data2);

    /*
    if(type1 == type2){
      if(isArrayType1 && isArrayType2 && expectedType == 'Array'){
        ret = '1';
      }else if(isArrayType1 && isArrayType2 && expectedType != 'Array'){
        ret = '2';
      }else if(!isArrayType1 && !isArrayType2 && expectedType == type1){
        ret = '1';
      }else if(!isArrayType1 && !isArrayType2 && expectedType != type2){
        ret = '2';
      }
    }
    */

    if(type1 == type2){
      if((isArrayType1 && isArrayType2) || (!isArrayType1 && !isArrayType2)){
        ret = '1'
      }
    }
    return ret;
  }

  hasMatchingElements(arrValuesSearch:Array<string | number>,analizedArray:Array<string | number>){
    return arrValuesSearch.some((value) => {
      return analizedArray.includes(value);
    });
  }
    /* FIXME: melhorar a função deepCloneWithFunctions para que consiga reagir a
  qualquer tipo de objeto com função como valor ou não, com array como valor...*/
  deepCloneWithFunctions(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(this.deepCloneWithFunctions);
    }

    const copy: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Copia a função diretamente
        if (typeof obj[key] === 'function') {
          copy[key] = obj[key].bind(copy);
        } else if(typeof obj[key] === 'object') {
          copy[key] = this.deepCloneWithFunctions(obj[key]);
        }else{
          copy[key] = obj[key];
        }
      }
    }
    return copy;
  }

  showNotification(methodRequisition:string, typeMessage:string, objMsg:Record<string,any>, duration:number = 9000, orientation:PoToasterOrientation = 0){
    var methodNotification:any = objMsg[methodRequisition][typeMessage].method;
    var msg:string = objMsg[methodRequisition][typeMessage].message;

    const poNotification: PoNotification = {
      message: msg,
      orientation: orientation,
      action: undefined,
      actionLabel: undefined,
      duration: duration
    };

    var test:any = this.poNotification;
    test[methodNotification](poNotification);
    //this.poNotification[methodTarget](poNotification);
  }
}
