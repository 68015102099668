export const URL = {
    token: `/api/v1/glb/auth`,
    users: `/api/v1/rm/users`,
    imgFolder: `/assets/img`,
    baseDimensions: {
      desktop: { width:1425, height:250 },
      mobile: { width:380, height:220 }
    },
    baseHom:'http://192.168.0.55:12200/RMSRestDataServer/rest',
    home:{},
    curriculum:{
      trajectories: '/api/v1/rm/trajectories', //FIXME: tirar do curriculum e colocar no home
      experiences: '/api/v1/rm/experiences',
      additionaleducations: '/api/v1/rm/additionaleducations',
      educations: '/api/v1/rm/educations',
      courses:'/api/v1/rm/courses',
      institutions:'/api/v1/rm/institutions',
      educationLevels:'/api/v1/rm/educationlevels'
    },
    user: {
      infos: '/api/v1/rm/users',
      avatar: '/api/v1/rm/users/avatar',
      branches: '/api/v1/rm/branches',
      affiliates: '/api/v1/rm/affiliates',
      costcenters:'/api/v1/rm/costcenters',
      categories:'/api/v1/rm/categories',
      sections:'/api/v1/rm/sections',
      segments:'/api/v1/rm/segments'
    },
    settings: {
      ads: '/api/v1/rm/ads',
      upload:'/api/v1/rm/ads/image'
    },
};
