import { Injectable } from '@angular/core';

import { StorageService } from './storage';
import { KEY_STORAGE } from '../enums/storage.enum';

@Injectable({
	providedIn: 'root'
})

export class LocalStorageService extends StorageService {
	constructor() {
		let type: string = KEY_STORAGE.TYPE
		if (type === "LOCAL"){
			super(window.localStorage);
		} else if (type === "SESSION"){
			super(window.sessionStorage);
		}
	}
}